import * as React from "react";
import {createComponent} from "../../../common/ptvm/view/createComponent";
import {mapDispatchToProps} from "../../../common/ptvm/view/mapDispatchToProps";
import {mapStateToProps} from "../../../common/ptvm/view/mapStateToProps";
import {ViewModel} from "../../types/ViewModel";
import "./ComingSoon.scss";
import {ComingSoonVm} from "./types/ComingSoonVm";

export const ComingSoon = createComponent<ViewModel, ComingSoonVm, {}>(
  ({}) => {
    return <div
      className={[
        "ComingSoon"
      ].join(" ")}>
      <div className={"logo"}>
        <h1>FLE3T</h1>
        <div className={"subtitle"}>
          Show off what you fly
        </div>
      </div>
      <div className={"footer"}>
        ...coming in 2023
      </div>
    </div>;
  },
  mapStateToProps,
  mapDispatchToProps
);
