import {ReactElement} from "react";
import {connect} from "react-redux";
import {EventsProps} from "./types/EventsProps";
import {ExternalProps} from "./types/ExternalProps";
import {InternalProps} from "./types/InternalProps";

export function createComponent<VM, VMN, E, P = {}>(
  createView: (props: InternalProps<VMN, E> & P) => ReactElement,
  mapStateToProps?: (
    viewModel: VM,
    ownProps: ExternalProps<E> & P
  ) => InternalProps<VMN, E>,
  mapDispatchToProps?: (
    dispatch: any,
    ownProps: ExternalProps<E> & P
  ) => EventsProps<E>
) {
  const canonicalCreateView =
    createView as (props: ExternalProps<E>) => ReactElement;
  return connect(mapStateToProps, mapDispatchToProps)(canonicalCreateView);
}
