import * as React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {App} from "./app/components/app/App";
import {vm} from "./vm";

// root div must exist
const rootElement = document.getElementById("root") as Element;
const root = createRoot(rootElement);
root.render(
  <Provider store={vm}>
    <App vmPath={["apps", "app"]}/>
  </Provider>
);