import {EventsProps} from "./types/EventsProps";
import {ExternalProps} from "./types/ExternalProps";

export function mapDispatchToProps<VMN, E>(
  dispatch: any,
  ownProps: ExternalProps<E>
): EventsProps<E> {
  const {events = {}} = ownProps;
  return {events};
}
