import * as React from "react";
import {createComponent} from "../../../common/ptvm/view/createComponent";
import {mapDispatchToProps} from "../../../common/ptvm/view/mapDispatchToProps";
import {mapStateToProps} from "../../../common/ptvm/view/mapStateToProps";
import {ViewModel} from "../../types/ViewModel";
import {ComingSoon} from "../coming-soon/ComingSoon";
import "./App.scss";
import {AppVm} from "./types/AppVm";

export const App = createComponent<ViewModel, AppVm, {}>(
  ({
    vmPath
  }) => {
    return <div
      className={[
        "App"
      ].join(" ")}>
      <ComingSoon vmPath={[...vmPath, "page"]}/>
    </div>;
  },
  mapStateToProps,
  mapDispatchToProps
);
