import {configureStore} from "@reduxjs/toolkit";
import {Store} from "redux";
import {ViewModel} from "./app/types/ViewModel";

export const vm: Store<ViewModel> = configureStore({
  // vm is never going to be undefined
  reducer: vm => vm as ViewModel,
  preloadedState: {
    "app": {
      "page": {}
    }
  }
});
