import {getVmNode} from "../view-model/getVmNode";
import {ExternalProps} from "./types/ExternalProps";
import {InternalProps} from "./types/InternalProps";

export function mapStateToProps<VM, VMN, E>(
  viewModel: VM,
  ownProps: ExternalProps<E>
): InternalProps<VMN, E> {
  const {vmPath, events} = ownProps;
  return {
    vmPath,
    vm: getVmNode(viewModel, vmPath) || {} as VMN,
    events
  };
}
